import {AfwezigheidDto, OldProfielType, ProfielDto, ProfielId, ProfielNaamType} from './profiel.dto';
import {ApiProperty} from '@nestjs/swagger';

export class ActieveJongereDto extends ProfielDto {
    @ApiProperty({type: String})
    readonly viewType!: 'JONGERE';
}

export class GeblokkeerdWeergaveJongereDto {
    @ApiProperty({type: String})
    readonly viewType!: 'JONGERE';
    @ApiProperty({type: String})
    readonly status = 'GEBLOKKEERDE_WEERGAVE';
    @ApiProperty({type: String})
    readonly id!: ProfielId;
    @ApiProperty()
    readonly voornaam!: string;
    @ApiProperty()
    readonly naam!: string;
    //TODO: Backwards compatibility: 1.003, remove unneeded profielType && afwezigheid
    @ApiProperty({type: String})
    readonly profielType!: OldProfielType;
    @ApiProperty()
    readonly afwezigheid!: AfwezigheidDto;
}

export class InactieveWeergaveJongereDto {
    @ApiProperty({type: String})
    readonly viewType!: 'JONGERE';
    @ApiProperty({type: String})
    readonly status = 'INACTIEVE_WEERGAVE';
    @ApiProperty({type: String})
    readonly id!: ProfielId;
    @ApiProperty()
    readonly voornaam!: string;
    @ApiProperty()
    readonly naam!: string;
}

export class UitgenodigdWeergaveJongereDto {
    @ApiProperty({type: String})
    readonly viewType!: 'JONGERE';
    @ApiProperty({type: String})
    readonly status = 'UITGENODIGDE_WEERGAVE';
    @ApiProperty({type: String})
    readonly id!: ProfielId;
    @ApiProperty({ type: String })
    readonly type!: 'JONGERE';
    @ApiProperty()
    readonly voornaam!: string;
    @ApiProperty()
    readonly naam!: string;
    @ApiProperty({type: String})
    readonly profielnaamType!: ProfielNaamType;
    @ApiProperty({required: false})
    readonly roepnaam?: string;
    //TODO: Backwards compatibility: 1.003, remove unneeded profielType && afwezigheid
    @ApiProperty()
    readonly afwezigheid!: AfwezigheidDto;
    @ApiProperty({type: String})
    readonly profielType!: OldProfielType;
}

export type JongereProfielDto =
    | ActieveJongereDto
    | GeblokkeerdWeergaveJongereDto
    | InactieveWeergaveJongereDto
    | UitgenodigdWeergaveJongereDto;
