import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ParkourButtonComponent, ParkourModalComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { OrganisationWithBookmark, WatWatArtikel } from '../../../shared/model/artikel';
import { ArtikelCardComponent } from '../artikel-card/artikel-card.component';
import { AsyncPipe } from '@angular/common';
import { GesprekOverviewCardPage } from '../../../shared/components/gesprek-overview-card/gesprek-overview-card.component';
import { Gesprek } from '../../../bericht/model/gesprek';
import { AangemeldeUser } from '../../../authentication/user';
import { HelplineCardItemComponent } from '../../../home/component/helpline-card-item/helpline-card-item.component';
import { combineLatest, Observable, shareReplay } from 'rxjs';
import { asResult, Result } from '../../../utils';
import { BerichtenService } from '../../../bericht/service/berichten.service';
import { GesprekId, ProfielId } from 'parkour-web-app-dto';
import { Context } from '../../../shared/model/context';
import { ContextService } from '../../../shared/services/context.service';
import AuthService from '../../../authentication/service/auth.service';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';

export interface IShareArtikel {
  gesprekId: GesprekId;
  gesprekNaam: string;
  resourceId: string;
  profielId: ProfielId;
}

@Component({
  selector: 'parkour-share-button',
  standalone: true,
  imports: [
    TranslateModule,
    ArtikelCardComponent,
    AsyncPipe,
    GesprekOverviewCardPage,
    HelplineCardItemComponent,
    ParkourButtonComponent,
    ParkourModalComponent,
    PageSectionDirective,
  ],
  templateUrl: './share-button.component.html',
})
export class ShareButtonComponent implements OnInit {
  @ViewChild(ParkourModalComponent) parkourModal!: ParkourModalComponent;

  @Input() artikel?: WatWatArtikel;
  @Input() organisation?: OrganisationWithBookmark;

  @Output() public shareArtikelOrOrganisation: EventEmitter<IShareArtikel> =
    new EventEmitter<IShareArtikel>();

  data$: Observable<
    Result<{
      user: AangemeldeUser;
      gesprekken: Gesprek[];
    }>
  > = new Observable();
  context$: Observable<Context> = new Observable();

  constructor(
    private readonly authService: AuthService,
    private readonly berichtenService: BerichtenService,
    private readonly contextService: ContextService,
  ) {}

  ngOnInit() {
    // this.data$ = asResult(
    //   combineLatest({
    //     user: this.authService.getAangemeldeUser$(),
    //     gesprekken: this.berichtenService.getGesprekken(),
    //   }),
    // ).pipe(shareReplay(1));

    this.data$ = asResult(
      combineLatest({
        user: this.authService.getAangemeldeUser$(),
        gesprekken: this.berichtenService.getGesprekken(),
      }),
    );

    this.context$ = this.contextService.context$.pipe(shareReplay(1));
  }

  openModal() {
    this.parkourModal.open();
  }

  onShare(gesprek: Gesprek, user: AangemeldeUser) {
    this.shareArtikelOrOrganisation.emit({
      gesprekId: gesprek.id,
      gesprekNaam: gesprek.getGesprekNaam(user),
      resourceId: this.organisation ? `${this.organisation?.id}` : `${this.artikel?.id}`,
      profielId: user.profielId,
    });

    this.parkourModal.close();
  }
}
