import {ApiProperty, getSchemaPath} from '@nestjs/swagger';
import {IdOf} from '../branded-type';
import {BeeldbelGesprekDto} from './beeldbellen.dto';
import {ProfielId} from './profiel.dto';
import {JongereProfielDto} from './jongere-profiel.dto';
import {ActiefTeamlidDto, GeblokkeerdTeamlidDto, InactiefTeamlidDto, TeamlidProfielDto,} from './teamlid-profiel.dto';

export type AfspraakId = IdOf<'afspraak'>;

export function validateAfspraakId(id?: string | null): AfspraakId {
    if (!id) {
        throw new Error('id is undefined');
    }

    return id as AfspraakId;
}

export class BasicAfspraakDto {
    readonly type!: 'BASIC';
    @ApiProperty({type: String})
    readonly titel!: string;
    @ApiProperty({type: String})
    readonly id!: AfspraakId;
    @ApiProperty({type: String})
    readonly jongereProfielId!: ProfielId;
    @ApiProperty({type: String})
    readonly van!: string;
    @ApiProperty({type: String})
    readonly tot!: string;
    @ApiProperty({type: String, required: false})
    readonly omschrijving?: string;
}

export class VideogesprekAfspraakDto {
    readonly type!: 'VIDEOGESPREK';
    @ApiProperty({type: String})
    readonly id!: AfspraakId;
    @ApiProperty({type: String})
    readonly jongereProfielId!: ProfielId;
    @ApiProperty({type: String})
    readonly teamlidProfielId!: ProfielId;
    @ApiProperty({type: String})
    readonly van!: string;
    @ApiProperty({type: String})
    readonly tot!: string;
    @ApiProperty({type: String, required: false})
    readonly omschrijving?: string;
}

export type AfspraakDto = BasicAfspraakDto | VideogesprekAfspraakDto;

export class BasicAfspraakViewDetailDto {
    @ApiProperty({type: String})
    readonly type!: 'BASIC';
    @ApiProperty()
    readonly titel!: string;
    @ApiProperty({type: String})
    readonly id!: AfspraakId;
    @ApiProperty()
    readonly jongere!: JongereProfielDto;
    @ApiProperty({type: String})
    readonly datum!: string;
    @ApiProperty({type: String, required: false})
    readonly omschrijving?: string;
    @ApiProperty({type: String})
    readonly van!: string;
    @ApiProperty({type: String})
    readonly tot!: string;
}

export class VideogesprekAfspraakViewDetailDto {
    @ApiProperty({type: String})
    readonly type!: 'VIDEOGESPREK';
    @ApiProperty({type: String})
    readonly id!: AfspraakId;
    readonly jongere!: JongereProfielDto;
    @ApiProperty({
        anyOf: [
            {$ref: getSchemaPath(ActiefTeamlidDto)},
            {$ref: getSchemaPath(GeblokkeerdTeamlidDto)},
            {$ref: getSchemaPath(InactiefTeamlidDto)},
        ],
    })
    readonly teamlid!: TeamlidProfielDto;
    @ApiProperty({type: String})
    readonly datum!: string;
    @ApiProperty({type: String, required: false})
    readonly omschrijving?: string;
    @ApiProperty({type: String})
    readonly van!: string;
    @ApiProperty({type: String})
    readonly tot!: string;
    @ApiProperty({required: false})
    readonly gesprek?: BeeldbelGesprekDto;
}

export type AfspraakViewDetailDto = BasicAfspraakViewDetailDto | VideogesprekAfspraakViewDetailDto;

export class BasicAfspraakInsertDto {
    @ApiProperty({type: String})
    readonly type!: 'BASIC';
    @ApiProperty({type: String})
    readonly titel!: string;
    @ApiProperty({type: String})
    readonly jongereProfielId!: ProfielId;
    @ApiProperty({type: String})
    readonly van!: string;
    @ApiProperty({type: String})
    readonly tot!: string;
    @ApiProperty({type: String, required: false})
    readonly omschrijving?: string;
}

export class VideogesprekAfspraakInsertDto {
    @ApiProperty({type: String})
    readonly type!: 'VIDEOGESPREK';
    @ApiProperty({type: String})
    readonly jongereProfielId!: ProfielId;
    @ApiProperty({type: String})
    readonly teamlidProfielId!: ProfielId;
    @ApiProperty({type: String})
    readonly van!: string;
    @ApiProperty({type: String})
    readonly tot!: string;
    @ApiProperty({type: String, required: false})
    readonly omschrijving?: string;
}

export type AfspraakInsertDto = BasicAfspraakInsertDto | VideogesprekAfspraakInsertDto;
export type AfspraakUpdateDto = AfspraakInsertDto & { id: AfspraakId };
