import { Injectable } from '@angular/core';
import { Avatar, MyProfielUpsertDto, ProfielNaamType } from 'parkour-web-app-dto';
import { ProfielService } from './profiel.service';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

export type NaamData = {
  roepnaam: string | undefined;
  profielnaamType: ProfielNaamType;
};

export type ProfielFotoData = {
  avatar: Avatar;
  profielFoto: string | undefined;
  profielFotoFile: File | undefined;
};

export type OverJezelfData = {
  bio: string | undefined;
  links: string[];
  adres: string | undefined;
};

export type ContactData = {
  email: string | undefined;
  telefoonnummer: string | undefined;
};

type SubmitFunction = (profiel: MyProfielUpsertDto, redirectUrl?: string) => Observable<void>;

@Injectable({
  providedIn: 'root',
})
export class ProfielCreateService {
  get currentlyCreating(): boolean {
    return this._currentlyCreating;
  }

  private _currentlyCreating = false;
  private submitFunction?: SubmitFunction;
  private hasJongereHoedanigheid?: () => Observable<boolean>;

  profielData: {
    naamData?: NaamData;
    profielFotoData?: ProfielFotoData;
    overJezelfData?: OverJezelfData;
    contactData?: ContactData;
  } = {};

  voorwaardenChecked = false;
  profielType: 'PROFESSIONEEL_TEAMLID' | 'JONGERE' | 'UNSPECIFIED' = 'UNSPECIFIED';

  constructor(
    private readonly profielService: ProfielService,
    private readonly router: Router,
  ) {}

  resetProfielData() {
    this.profielData = {};
    this.voorwaardenChecked = false;
    this.profielType = 'UNSPECIFIED';
  }

  async startCreatingProfiel(
    redirectUrl: string,
    submitFunction: SubmitFunction,
    hasJongereHoedanigheid: () => Observable<boolean>,
    type: 'persoonlijk' | 'professioneel' | 'unspecified',
  ) {
    this._currentlyCreating = true;
    this.hasJongereHoedanigheid = hasJongereHoedanigheid;
    this.submitFunction = submitFunction;

    if (type === 'persoonlijk') {
      this.startCreatingPersoonlijkProfiel();
    } else if (type === 'professioneel') {
      this.startCreatingProfessioneelProfiel();
    } else {
      await this.router.navigate(['/app', 'start', 'profiel', 'nieuw'], {
        queryParams: { redirectUrl },
      });
    }
  }

  private startCreatingProfessioneelProfiel() {
    this.profielType = 'PROFESSIONEEL_TEAMLID';
    this.router.navigate(['/app', 'start', 'profiel', 'nieuw', 'voorwaarden'], {
      queryParamsHandling: 'preserve',
    });
  }

  private startCreatingPersoonlijkProfiel() {
    this.hasJongereHoedanigheid?.()
      .pipe(tap((isJongere) => (this.profielType = isJongere ? 'JONGERE' : 'UNSPECIFIED')))
      .subscribe(() =>
        this.router.navigate(['/app', 'start', 'profiel', 'nieuw', 'voorwaarden'], {
          queryParamsHandling: 'preserve',
        }),
      );
  }

  isProfessioneel(): boolean {
    return this.profielType === 'PROFESSIONEEL_TEAMLID';
  }

  setProfielFotoData(profielFotoData: ProfielFotoData) {
    this.profielData.profielFotoData = profielFotoData;
  }

  setNaamData(naamData: NaamData) {
    this.profielData.naamData = naamData;
  }

  setOverJezelfData(overJezelfData: OverJezelfData) {
    this.profielData.overJezelfData = overJezelfData;
  }

  setContactData(contactData: ContactData) {
    this.profielData.contactData = contactData;
  }

  setVoorwaardenChecked(checked: boolean) {
    this.voorwaardenChecked = checked;
  }

  createMyProfiel(route: ActivatedRoute): Observable<unknown> {
    if (!this.voorwaardenChecked) {
      throw new Error('Voorwaarden not checked');
    }

    const profielFotoData = this.profielData.profielFotoData;
    const contactData = this.profielData.contactData;
    const overJezelfData = this.profielData.overJezelfData;
    const naamData = this.profielData.naamData;

    if (!contactData || !overJezelfData || !naamData || !profielFotoData) {
      throw new Error('Profile data not complete');
    }

    return this.createProfielWithoutProfielFoto(
      naamData,
      overJezelfData,
      contactData,
      profielFotoData,
    ).pipe(
      switchMap(() => {
        if (profielFotoData.profielFotoFile) {
          return this.profielService.uploadMyProfielFoto(profielFotoData.profielFotoFile);
        } else {
          return of(undefined);
        }
      }),
      switchMap(() => {
        this.resetProfielData();
        return this.router.navigate(['../', 'succes'], {
          relativeTo: route,
          queryParamsHandling: 'preserve',
        });
      }),
      tap(() => {
        this._currentlyCreating = false;
      }),
    );
  }

  private createProfielWithoutProfielFoto(
    naamData: NaamData,
    overJezelfData: OverJezelfData,
    contactData: ContactData,
    profielFotoData: ProfielFotoData,
    redirectUrl?: string,
  ) {
    if (!this.submitFunction) {
      throw new Error('Submit function not set');
    }

    return this.submitFunction(
      {
        ...naamData,
        ...overJezelfData,
        ...contactData,
        type: this.profielType,
        avatar: profielFotoData.avatar,
      },
      redirectUrl,
    );
  }

  submitProfielType(type: 'persoonlijk' | 'professioneel') {
    if (type === 'persoonlijk') {
      this.startCreatingPersoonlijkProfiel();
    } else {
      this.startCreatingProfessioneelProfiel();
    }
  }
}
