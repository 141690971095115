import {ApiProperty} from '@nestjs/swagger';
import {ProfielId} from './profiel.dto';

export class DrieKolommenDocumentDto {
    @ApiProperty()
    readonly id!: string;
    @ApiProperty({type: String})
    readonly jongereProfielId!: ProfielId;
    @ApiProperty()
    readonly eyouthRef!: string;
    @ApiProperty()
    readonly startDatum!: string;
    @ApiProperty()
    readonly rubrieken!: Array<DrieKolommenRubriek>;
    @ApiProperty({type: String})
    readonly type!: 'DRIE_KOLOMMEN';
}

export type Rubriek = 'ZORGEN' | 'KRACHTEN' | 'ACTIES';

export class DrieKolommenRubriek {
    @ApiProperty({type: String})
    readonly rubriek!: Rubriek;
    @ApiProperty()
    readonly subrubriek!: string;
    @ApiProperty()
    readonly inhoud!: string;
}
