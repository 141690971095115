import { Injectable } from '@angular/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { AnalyticsEvent, EventCategory } from './analytics-event.model';
import { ProfielType } from 'parkour-web-app-dto';
import { ContextType } from '../shared/services/context.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private readonly tracker: MatomoTracker) {}

  public trackEvent<C extends EventCategory>(event: AnalyticsEvent<C>): void {
    this.tracker.trackEvent(event.category, event.event, event.name);
  }

  public optUserOut(): void {
    this.tracker.optUserOut();
  }

  public setProfielType(profielType: ProfielType): void {
    this.tracker.setCustomDimension(environment.matomo.dimensions.profielType, profielType);
  }

  public setContextType(contextType: ContextType): void {
    this.tracker.setCustomDimension(environment.matomo.dimensions.contextType, contextType);
  }

  public forgetUserOptOut(): void {
    this.tracker.forgetUserOptOut();
  }

  public isUserOptedOut() {
    return this.tracker.isUserOptedOut();
  }
}
