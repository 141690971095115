import {ApiProperty} from "@nestjs/swagger";
import {IdOf} from "../branded-type";

type FileId = IdOf<'file'>;

export class FileData {
    @ApiProperty({ type: String })
    readonly id!: FileId;
    @ApiProperty()
    readonly naam!: string;
}