export const generalBerichtAnalyticsEvents = [
  'bewaarAlsNotitieClick',
  'tekstBerichtVerstuurd',
  'bestandBerichtVerstuurd',
  'spraakBerichtVerstuurd',
  'berichtVerwijderd',
  'berichtEmojiToegevoegd',
  'berichtReactieToegevoegd',
  'berichtReactieVerwijderd',
  'berichtGerapporteerd',
  'berichtBewaard',
] as const;

export const berichtenAnalyticsEvents = [
  ...generalBerichtAnalyticsEvents,
  'groepsgesprekAangemaakt',
  'groepsgesprekAfbeeldingToegevoegd',
  'groepsgesprekAfbeeldingVerwijderd',
  'groepsgesprekVerlaten',
  'gesprekGearchiveerd',
  'artikelGedeeld',
] as const;
