import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';

export function videogesprekDuration(startTimestamp: string, endTimestamp: string) {
  const start = moment(new Date(startTimestamp));
  const end = moment(new Date(endTimestamp));

  const duration = moment.duration(end.diff(start));
  const durationSeconds = duration.asSeconds();
  if (durationSeconds > 60) {
    const minuten = duration.asMinutes().toFixed(0);
    const suffix = minuten === '1' ? ' minuut' : ' minuten';
    return minuten + suffix;
  } else return durationSeconds.toFixed(0) + ' seconden';
}

@Pipe({
  name: 'videogesprekDuration',
  standalone: true,
})
export class VideogesprekDurationPipe implements PipeTransform {
  constructor() {}
  transform(startTimestamp: string, endTimestamp: string): string {
    return videogesprekDuration(startTimestamp, endTimestamp);
  }
}
