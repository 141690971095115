import { Pipe, PipeTransform } from '@angular/core';
import { Gesprek } from 'src/app/bericht/model/gesprek';
import { getIsTimeToday } from '../../../utils';
import { TranslatePipe } from '@ngx-translate/core';
import { AangemeldeUser } from '../../../authentication/user';
import { BerichtenDatePipe } from '../../../bericht/pipes/berichten-date.pipe';

@Pipe({
  name: 'ariaLabelGesprek',
  standalone: true,
})
export class AriaLabelGesprekPipe implements PipeTransform {
  constructor(
    private readonly translate: TranslatePipe,
    private readonly berichtenDatePipe: BerichtenDatePipe,
  ) {}

  transform(gesprek: Gesprek, laatsteBerichtInhoud: string | null, user: AangemeldeUser): string {
    if (gesprek.isGroepsgesprek()) {
      if (getIsTimeToday(gesprek.laatsteBericht?.timestamp ?? gesprek.aangemaaktOp)) {
        return this.translate.transform(
          'berichten.gesprekken-overview-page.groepsgesprek-card-time',
          {
            amount: gesprek.getDeelnemersAndJongere().length,
            gesprekNaam: gesprek.getGesprekNaam(user),
            bericht: laatsteBerichtInhoud,
            time: this.getLaatsteBerichtTijdstip(gesprek),
          },
        );
      } else {
        return this.translate.transform(
          'berichten.gesprekken-overview-page.groepsgesprek-card-date',
          {
            amount: gesprek.getDeelnemersAndJongere().length,
            gesprekNaam: gesprek.getGesprekNaam(user),
            bericht: laatsteBerichtInhoud,
            date: this.getLaatsteBerichtTijdstip(gesprek),
          },
        );
      }
    } else {
      if (getIsTimeToday(gesprek.laatsteBericht?.timestamp ?? gesprek.aangemaaktOp)) {
        return this.translate.transform('berichten.gesprekken-overview-page.gesprek-card-time', {
          gesprekNaam: gesprek.getGesprekNaam(user),
          bericht: laatsteBerichtInhoud,
          time: this.getLaatsteBerichtTijdstip(gesprek),
        });
      } else {
        return this.translate.transform('berichten.gesprekken-overview-page.gesprek-card-date', {
          gesprekNaam: gesprek.getGesprekNaam(user),
          bericht: laatsteBerichtInhoud,
          date: this.getLaatsteBerichtTijdstip(gesprek),
        });
      }
    }
  }

  getLaatsteBerichtTijdstip(gesprek: Gesprek) {
    return this.berichtenDatePipe.transform(
      gesprek.laatsteBericht?.timestamp ?? gesprek.aangemaaktOp,
    );
  }
}
