import { Component, Input } from '@angular/core';
import { CoreModule } from '../../../core/core.module';
import { ParkourInfoCardComponent } from '@parkour/ui';

@Component({
  standalone: true,
  selector: 'parkour-content-unavailable-card',
  templateUrl: './content-unavailable-card.component.html',
  imports: [CoreModule, ParkourInfoCardComponent],
})
export class ContentUnavailableCardComponent {
  @Input() title = 'Oeps…';
  @Input() description = 'We kunnen nu niet alle inhoud weergeven, probeer later opnieuw.';
}
