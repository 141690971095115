import { Component } from '@angular/core';
import { ProfielService } from '../../service/profiel.service';
import { combineLatest, Observable, of } from 'rxjs';
import { ProfielCreateService } from '../../service/profiel-create.service';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ProfielDetailsComponent } from '../../../profiel/component/profile-details/profiel-details.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { MijnProfiel, Profiel } from '../../model/profiel';
import AuthService from '../../../authentication/service/auth.service';
import { AangemeldeUser } from '../../../authentication/user';
import { ProfielCreateOption } from 'parkour-web-app-dto';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../shared/marks-meldingen-as-read';
import { meldingenForPage } from '../../../meldingen/config';

export type MijnProfielPageData = {
  profielCreateOptions: ProfielCreateOption[];
  mijnProfielen: MijnProfiel[];
  user: AangemeldeUser;
  profiel: MijnProfiel;
};

@Component({
  standalone: true,
  templateUrl: './mijn-profiel.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourHeaderActionComponent,
    NgIf,
    ContentUnavailableCardComponent,
    PageLayoutComponent,
    ProfielDetailsComponent,
    AsyncPipe,
    TranslateModule,
    IonRefresher,
    IonRefresherContent,
    IonContent,
    ParkourRefreshDirective,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonBackButton,
    PageContentDirective,
  ],
})
export class MijnProfielPage
  extends ParkourDataPage<MijnProfielPageData>
  implements MarksMeldingenAsReadOnView
{
  constructor(
    private readonly profielService: ProfielService,
    private readonly authService: AuthService,
    private readonly profielCreateService: ProfielCreateService,
  ) {
    super();
  }

  getMeldingenToMarkAsRead() {
    return of(
      new MeldingenToMarkAsRead(
        meldingenForPage.PROFIEL_TAB_MOBILE.MIJN_PROFIEL_PAGE.specificMeldingen,
      ),
    );
  }

  override retrieveMainData(): Observable<MijnProfielPageData> {
    return combineLatest({
      mijnProfielen: this.profielService.getMyProfielen(),
      profiel: this.profielService.getCurrentUserProfiel$(),
      profielCreateOptions: this.profielService.getProfielCreateOptions(),
      user: this.authService.getAangemeldeUser$(),
    });
  }

  onProfielSelect(profiel: Profiel) {
    this.authService.switchProfiel(profiel.id, '/app/me/profiel/mijn').subscribe();
  }

  async onCreatePersoonlijkProfiel() {
    await this.profielCreateService.startCreatingProfiel(
      '/app/me/profiel/mijn',
      this.authService.createMyProfiel,
      this.authService.hasJongereHoedanigheid,
      'persoonlijk',
    );
  }

  async createProfessioneelProfiel() {
    await this.profielCreateService.startCreatingProfiel(
      '/app/me/profiel/mijn',
      this.authService.createMyProfiel,
      this.authService.hasJongereHoedanigheid,
      'professioneel',
    );
  }
}
