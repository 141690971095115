import {BrandedType} from '../branded-type';
import {ApiProperty} from '@nestjs/swagger';
import {Page} from './page.dto';

export type NotitieId = BrandedType<string, 'notitie'>;

export function validateNotitieId(id?: string | null): NotitieId {
    if (!id) {
        throw new Error('id is undefined');
    }

    return id as NotitieId;
}

export class NotitieUpsertDto {
    @ApiProperty()
    readonly titel!: string;
    @ApiProperty({required: false})
    readonly beschrijving?: string;
}

export class NotitieDto {
    @ApiProperty({type: String})
    readonly id!: NotitieId;
    @ApiProperty()
    readonly titel!: string;
    @ApiProperty()
    readonly beschrijving!: string;
}

export class NotitiePageDto implements Page<NotitieDto> {
    @ApiProperty({type: [NotitieDto]})
    readonly content!: NotitieDto[];
    @ApiProperty()
    readonly totalPages!: number;
    @ApiProperty()
    readonly number!: number;
    @ApiProperty()
    readonly last!: boolean;
}
