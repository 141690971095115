export const verhaalEvents = [
  'aangemaakt',
  'verwijderd',
  'gedeeldMetNiemand',
  'gedeeldMetSpecifiek',
  'gedeeldMetIedereen',
  'gewijzigd',
  'afbeeldingToegevoegd',
  'afbeeldingVerwijderd',
  'bijlageDownloaded',
  'bijlageToegevoegd',
  'voorstelAangemaakt',
  'voorstelVerwijderd',
  'voorstelStatusGewijzigd',
  'voorstelBijlageDownloaded',
  'voorstelAanvaard',
  'voorstelGeweigerd',
  'jeugdhulphistoriekVerborgen',
  'jeugdhulphistoriekZichtbaar',
  'zoek',
] as const;
