// Error class for human readable errors, message will be shown to users.

export class HumanReadableError extends Error {}

function getMessage(messageConfig: string | { headerKey: string; messageKey: string }) {
  if (typeof messageConfig === 'string') {
    return messageConfig;
  } else {
    return messageConfig.messageKey;
  }
}

export class DebugError extends HumanReadableError {}
export class TranslatedHumanReadableError extends HumanReadableError {
  constructor(
    private readonly messageConfig: string | { headerKey: string; messageKey: string },
    options: { cause?: Error } = {},
  ) {
    super(getMessage(messageConfig), options);
  }

  getHeaderKey() {
    return typeof this.messageConfig === 'string'
      ? 'errors.default-error.title'
      : this.messageConfig.headerKey;
  }

  getMessageKey() {
    return getMessage(this.messageConfig);
  }
}
