import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FailureType } from '../../../utils';
import { ParkourButtonComponent, ParkourLottiePlayerComponent } from '@parkour/ui';
import { getActiveTab, Tab } from '../../../tabs/routes';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-error-component',
  templateUrl: './error.component.html',
  imports: [ParkourLottiePlayerComponent, ParkourButtonComponent, TranslateModule],
})
export class ErrorComponent implements OnInit {
  @Input() public errorType: FailureType = 'not-found';
  @Input()
  public refresh: (() => void) | 'none' = 'none';
  protected readonly environment = environment;
  protected activeTab: Tab | undefined = undefined;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.activeTab = getActiveTab(this.activatedRoute.snapshot);
  }

  onClickNavigateRootTab() {
    const activeTab = getActiveTab(this.activatedRoute.snapshot);

    if (activeTab) {
      this.router.navigate(['/', 'app', 'me', activeTab], { onSameUrlNavigation: 'reload' });
    } else {
      this.router.navigate(['/'], { onSameUrlNavigation: 'reload' });
    }
  }

  onClickNavigateHome() {
    this.router.navigate(['/'], { onSameUrlNavigation: 'reload' });
  }

  getDescriptionKey(): string {
    switch (this.errorType) {
      case 'unknown':
        return 'errors.unknown.page.description';
      case 'offline':
        return 'errors.offline.page.description';
      case 'not-found':
        return 'errors.not-found.page.description';
      case 'server-error':
        return 'errors.server-error.page.description';
      case 'server-unreachable':
        return 'errors.server-unreachable.page.description';
    }
  }

  getTitleKey(): string {
    switch (this.errorType) {
      case 'unknown':
        return 'errors.unknown.page.title';
      case 'offline':
        return 'errors.offline.page.title';
      case 'not-found':
        return 'errors.not-found.page.title';
      case 'server-error':
        return 'errors.server-error.page.title';
      case 'server-unreachable':
        return 'errors.server-unreachable.page.title';
    }
  }
}
