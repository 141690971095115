import { Component, Input } from '@angular/core';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Gesprek } from '../../../bericht/model/gesprek';
import { ParkourActionComponent, ParkourNewIndicatorComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { VideogesprekDurationPipe } from '../../../bericht/pipes/videogesprek-duration.pipe';
import { AangemeldeUser } from '../../../authentication/user';
import { GesprekId, MeldingOfType } from 'parkour-web-app-dto';
import { BerichtenDatePipe } from '../../../bericht/pipes/berichten-date.pipe';
import { ProfielnaamFromProfielidPipe } from '../../pipes/profielnaam-from-profielid.pipe';
import { LaatsteBerichtInhoudPipe } from './laatste-bericht-inhoud.pipe';
import { GesprekFotoBadgeComponent } from '../../../bericht/component/gesprek-foto-badge/gesprek-foto-badge.component';
import { AriaLabelGesprekPipe } from './aria-label-gesprek.pipe';

@Component({
  selector: 'parkour-gesprek-overview-card',
  standalone: true,
  imports: [
    AsyncPipe,
    GesprekFotoBadgeComponent,
    TranslateModule,
    NgIf,
    NgTemplateOutlet,
    ParkourActionComponent,
    ParkourNewIndicatorComponent,
    LaatsteBerichtInhoudPipe,
    AriaLabelGesprekPipe,
  ],
  providers: [BerichtenDatePipe, VideogesprekDurationPipe, ProfielnaamFromProfielidPipe],
  templateUrl: './gesprek-overview-card.component.html',
})
export class GesprekOverviewCardPage {
  @Input({ required: true }) gesprek!: Gesprek;
  @Input({ required: true }) user!: AangemeldeUser;
  @Input() ongelezenBerichtenMeldingen?:
    | MeldingOfType<'NIEUW_CHAT_BERICHT' | 'NIEUW_CHAT_REACTIE' | 'VIDEOGESPREK_GESTART'>[]
    | null;
  @Input() isStaticCard: boolean = false;

  constructor(private readonly berichtenDatePipe: BerichtenDatePipe) {}

  shouldShowNewIndicator(
    gesprekId: string,
    ongelezenMeldingen: {
      metaData: {
        gesprekId: GesprekId;
      };
    }[],
  ): boolean {
    if (ongelezenMeldingen) {
      return (
        ongelezenMeldingen.find((melding) => melding.metaData.gesprekId === gesprekId) !== undefined
      );
    } else {
      return false;
    }
  }

  getLaatsteBerichtTijdstip(gesprek: Gesprek) {
    return this.berichtenDatePipe.transform(
      gesprek.laatsteBericht?.timestamp ?? gesprek.aangemaaktOp,
    );
  }
}
