<ion-header>
  <ion-toolbar>
    <ng-container *parkourShowIfSuccessful="data$ | async; let data">
      <ion-buttons slot="start">
        <ion-back-button [parkourBackButton]="['../..']" />

        @if (data.gesprek.isGroepsgesprek()) {
          <parkour-action
            (clicked)="onOpenSettingsModal(data.gesprek)"
            classes="flex items-center gap-x-2 ml-1 overflow-hidden flex-1 pr-4 [--outline-offset:0!important]"
          >
            <ng-container [ngTemplateOutlet]="headerContent"></ng-container>
          </parkour-action>
        } @else {
          <div class="flex items-center gap-x-2 ml-1 overflow-hidden flex-1 pr-4">
            <ng-container [ngTemplateOutlet]="headerContent"></ng-container>
          </div>
        }

        <ng-template #headerContent>
          <parkour-gesprek-foto-badge
            [gesprek]="data.gesprek"
            [overrideImage]="false"
            [size]="'2'"
            [user]="data.user"
          />

          <div class="grid overflow-hidden">
            <h1 class="typo-body variant-body-fat truncate" data-testid="gesprekspartner-naam">
              {{ data.gesprek.getGesprekNaam(data.user) }}
            </h1>

            <span class="typo-body variant-body-small text-p-300 flex align-center gap-x-1">
              <span>{{ data.gesprek.getSubtitleForHeader(data.user) }}</span>

              @if (data.gesprek.getVisibleDeelnemer(data.user.profielId); as visibleDeelnemer) {
                @if (
                  visibleDeelnemer.status === 'ACTIEF' &&
                  visibleDeelnemer.type === 'PROFESSIONEEL_TEAMLID'
                ) {
                  <parkour-professioneel-indicator />
                }
              }
            </span>
          </div>
        </ng-template>
      </ion-buttons>

      <ion-buttons class="flex-none" slot="end">
        @if (data.gesprek.isGroepsgesprek()) {
          <parkour-header-action
            [link]="['instellingen']"
            [label]="'buttons.instellingen' | translate"
            icon="settings"
          />
        } @else if (data.gesprek.isGesprekWithBegeleider()) {
          <parkour-header-action
            (clicked)="onStartVideogesprek(data.profiel, data.gesprek)"
            [label]="'buttons.start-videocall' | translate"
            [disabled]="busyJoiningGesprek"
            icon="video-camera"
          />
        }
      </ion-buttons>
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false">
  <parkour-page-gradient />

  <ng-container *parkourPageContent="data$ | async; refresh: 'none'; let data">
    <div
      [ngClass]="{ 'opacity-100': isBerichtPopoverOpened, 'opacity-0': !isBerichtPopoverOpened }"
      class="absolute top-0 left-0 w-full h-full z-40 bg-n-200/80 backdrop-blur-sm pointer-events-none transition-opacity"
    ></div>

    <div
      #chatFlowScroll
      class="scrollbar ion-content-scroll-host flex flex-col h-full overflow-y-auto lg:ml-auto lg:w-[calc(100%-var(--app-sidebar-width))] overscroll-contain"
    >
      <div
        class="relative container-space lg:pt-[calc(var(--app-toolbar-height)+var(--app-plus-global-header-height))]"
      >
        <ion-infinite-scroll
          (ionInfinite)="onIonInfinite($event)"
          [disabled]="berichtenContainer.isLastPage"
          [threshold]="'0'"
          class="pt-4"
          position="top"
        >
          <div class="infinite-scroll-content">
            <parkour-loading-spinner />
          </div>
        </ion-infinite-scroll>

        <div>
          @if (berichtenContainer.isLastPage) {
            <div class="flex flex-col items-center gap-y-4 pt-8">
              <parkour-gesprek-foto-badge
                [gesprek]="data.gesprek"
                [size]="'5'"
                [user]="data.user"
                [overrideImage]="false"
              />

              <div class="flex flex-col items-center gap-y-1">
                <p class="text-center typo-h4">{{ data.gesprek.getGesprekNaam(data.user) }}</p>
                @if (!data.gesprek.isGroepsgesprek()) {
                  <span class="text-center typo-body variant-body-small">{{
                    data.gesprek.getSubtitleForHeader(data.user)
                  }}</span>
                }
                <span class="text-center text-n-600 font-normal">{{
                  'berichten.gesprekken-overview-page.laatste-bericht-default' | translate
                }}</span>
              </div>
            </div>
          }

          <div #chatFlow class="mt-6">
            <parkour-berichten
              (closedPopover)="closedBerichtMessagePopover()"
              (deleteReactie)="onDeleteReactie($event)"
              (deleted)="onDeleteBericht($event)"
              (openedPopover)="openedBerichtMessagePopover()"
              (rapporteer)="onRapporteer($event)"
              (replyBericht)="onReplyBericht($event)"
              (saveNotitie)="onSaveNotitie($event)"
              (scrollToBottom)="onScrollToBottom()"
              (selectedReactie)="onSelectedReactie($event, 'chat')"
              (videoGesprekJoin)="onVideogesprekJoinClick($event)"
              [berichten]="berichtenContainer.berichten | berichtenData"
              [gesprekId]="gesprekId"
              [gesprek]="data.gesprek"
              [profiel]="data.profiel"
            />
          </div>

          <div #chatAnchor></div>
        </div>

        <parkour-modal #settingsModal [title]="getModalTitle(data.gesprek)" icon="people">
          <parkour-groepsgesprek-deelnemer-list [gesprek]="data.gesprek" content />

          @if (data.gesprek.getGeblokkeerdeDeelnemers().length > 0) {
            <parkour-groepsgesprek-geblokkeerde-deelnemer-list [gesprek]="data.gesprek" footer />
          }
        </parkour-modal>
      </div>
    </div>
  </ng-container>
</ion-content>

<ion-footer class="lg:ml-auto lg:w-[calc(100%-var(--app-sidebar-width))]">
  <div class="container-space">
    <ng-container *parkourShowIfSuccessful="data$ | async; let data">
      <div
        [ngClass]="{ 'opacity-100': isBerichtPopoverOpened, 'opacity-0': !isBerichtPopoverOpened }"
        class="absolute top-0 left-0 w-full h-full z-40 bg-n-200/80 backdrop-blur-sm pointer-events-none transition-opacity"
      ></div>

      @if (
        (data.gesprek.isGroepsgesprek() ||
          data.gesprek.getVisibleDeelnemer(data.user.profielId).status === 'ACTIEF') &&
        data.gesprek.status !== 'GEARCHIVEERD'
      ) {
        <parkour-bericht-footer
          #berichtFooter
          (stuurBericht)="onStuurBericht($event, 'chat')"
          (uploadFile)="onUploadFile($event, 'chat')"
          (closeReplyBericht)="onCloseReplyBericht()"
          [busyUploadingFile]="busyUploadingFile"
          [deelnemerId]="data.user.profielId"
          [uploadingPercentage]="uploadingPercentage"
          [isReplyBerichtMode]="isReplyBerichtMode"
          [replyBericht]="replyBericht"
        />
      } @else {
        @if (data.gesprek.getVisibleDeelnemer(data.user.profielId); as partner) {
          <parkour-info-card class="text-n-1000 block py-5">
            <p>
              @if (data.gesprek.status === 'GEARCHIVEERD') {
                {{ data.gesprek.getGearchiveerdMessage() }}
              } @else if (partner.status === 'GEBLOKKEERD') {
                {{
                  'berichten.gesprek-page.geblokkeerd' | translate: { naam: partner | profielnaam }
                }}
              } @else if (partner.status === 'INACTIEF') {
                {{ 'berichten.gesprek-page.inactief' | translate: { naam: partner | profielnaam } }}
              }
            </p>
          </parkour-info-card>
        }
      }
    </ng-container>
  </div>
</ion-footer>
