import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FailureType, Result } from '../../utils';
import { ParkourLoadingSpinnerComponent } from '@parkour/ui';
import { ContentUnavailableCardComponent } from '../components/content-unavailable-card/content-unavailable-card.component';
import { combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

type SectionData<T> = {
  data: Result<T> | null;
  customErrorDescription?: string;
  hideSpinner?: boolean;
};

@Directive({
  standalone: true,
  selector: '[parkourSectionContent]',
})
export class PageSectionDirective<T> {
  constructor(
    private readonly templateRef: TemplateRef<{ $implicit: T }>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly translateService: TranslateService,
  ) {}

  private embeddedViewRef?: EmbeddedViewRef<{ $implicit: T }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private context: any = { $implicit: null };

  static ngTemplateContextGuard<T>(
    dir: PageSectionDirective<T>,
    context: unknown,
  ): context is { $implicit: T } {
    return true;
  }

  private getTitle(failureType: FailureType): Observable<string> {
    switch (failureType) {
      case 'unknown':
        return this.translateService.get('errors.unknown.card.title');
      case 'offline':
        return this.translateService.get('errors.offline.card.title');
      case 'not-found':
        return this.translateService.get('errors.not-found.card.title');
      case 'server-error':
        return this.translateService.get('errors.server-error.card.title');
      case 'server-unreachable':
        return this.translateService.get('errors.server-unreachable.card.title');
    }
  }

  private getDescription(failureType: FailureType): Observable<string> {
    switch (failureType) {
      case 'unknown':
        return this.translateService.get('errors.unknown.card.description');
      case 'offline':
        return this.translateService.get('errors.offline.card.description');
      case 'not-found':
        return this.translateService.get('errors.not-found.card.description');
      case 'server-error':
        return this.translateService.get('errors.server-error.card.description');
      case 'server-unreachable':
        return this.translateService.get('errors.server-unreachable.card.description');
    }
  }

  @Input()
  set parkourSectionContent(sectionData: SectionData<T>) {
    if (sectionData.data) {
      if (sectionData.data.success) {
        this.context.$implicit = sectionData.data.value;

        if (!this.embeddedViewRef) {
          this.viewContainerRef.clear();
          this.embeddedViewRef = this.viewContainerRef.createEmbeddedView(
            this.templateRef,
            this.context,
          );
        }
      } else {
        this.viewContainerRef.clear();
        this.embeddedViewRef = undefined;

        combineLatest({
          title: this.getTitle(sectionData.data.failureType),
          description: this.getDescription(sectionData.data.failureType),
        }).subscribe(({ title, description }) => {
          const contentUnavailableCardComponentComponentRef = this.viewContainerRef.createComponent(
            ContentUnavailableCardComponent,
          );
          contentUnavailableCardComponentComponentRef.setInput('title', title);
          contentUnavailableCardComponentComponentRef.setInput(
            'description',
            sectionData.customErrorDescription ?? description,
          );
        });
      }
    } else {
      this.embeddedViewRef = undefined;
      this.viewContainerRef.clear();

      if (!sectionData.hideSpinner) {
        this.viewContainerRef.createComponent(ParkourLoadingSpinnerComponent);
      }
    }
  }
}
