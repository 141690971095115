<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="verhaalHeroSources"
      [title]="'verhaal.overview-page.title' | translate"
    >
      <parkour-ex-jongere-info-card info-card />
      <div class="container-1-col">
        <div class="mt-8">
          <ng-container
            *parkourSectionContent="
              {
                data: mainPageData$ | async,
              };
              let verhaal
            "
          >
            <parkour-gebeurtenis-list [verhaal]="verhaal" [ongelezenGebeurtenisMeldingen]="[]" />
          </ng-container>
        </div>
      </div>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
