import { Component } from '@angular/core';
import { ArtikelId, BookmarkId, parseArtikelId, WatWatSelfTest } from 'parkour-web-app-dto';
import { map, Observable, switchMap } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { themaMapping } from '../../../home/model/thema.const';
import { Result } from '../../../utils';
import { ArtikelWithBookmark, WatwatService } from '../../../shared/services/watwat.service';
import { BookmarkService } from '../../../shared/services/bookmark.service';
import {
  ParkourBannerComponent,
  ParkourDividerComponent,
  ParkourImageComponent,
  ParkourPageGradientComponent,
  ParkourToastService,
} from '@parkour/ui';
import { ArtikelSelfTestItemComponent } from '../../components/artikel-self-test-item/artikel-self-test-item.component';
import { ArtikelContentComponent } from '../../components/artikel-component/artikel-content.component';
import { AsyncPipe } from '@angular/common';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ArtikelHelplineMethodsComponent } from '../../components/artikel-helplines/artikel-helpline-methods.component';
import { CoreModule } from '../../../core/core.module';
import {
  IShareArtikel,
  ShareButtonComponent,
} from '../../components/share-button/share-button.component';
import { BerichtenService } from '../../../bericht/service/berichten.service';
import { BookmarkButtonComponent } from '../../../shared/components/bookmark-button/bookmark-button.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { CustomPageTitle, PageTitle } from '../../../shared/custom-page-title';

@Component({
  templateUrl: './artikel.page.html',
  standalone: true,
  imports: [
    ArtikelSelfTestItemComponent,
    ArtikelContentComponent,
    AsyncPipe,
    ParkourBackButtonDirective,
    ArtikelHelplineMethodsComponent,
    RouterLink,
    CoreModule,
    ShareButtonComponent,
    ParkourPageGradientComponent,
    ParkourImageComponent,
    ParkourBannerComponent,
    BookmarkButtonComponent,
    ParkourDividerComponent,
    PageLayoutComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    PageContentDirective,
    ShowIfSuccesfulDirective,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
  ],
})
export class ArtikelPage extends ParkourDataPage<ArtikelWithBookmark> implements CustomPageTitle {
  standalone$: Observable<boolean> = this.route.data.pipe(
    map((data) => 'standalone' in data && data['standalone']),
  );

  selfTestsResult$: Observable<Result<WatWatSelfTest[]>> = this.mainPageData$.pipe(
    switchMap((artikelResult) =>
      artikelResult.mapToObservable((artikel) =>
        this.watwatService.getSelfTests(artikel.selfTests),
      ),
    ),
  );

  protected readonly themaMapping = themaMapping;

  constructor(
    private readonly watwatService: WatwatService,
    private readonly bookmarkService: BookmarkService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly berichtenService: BerichtenService,
    private readonly route: ActivatedRoute,
    private readonly toastService: ParkourToastService,
  ) {
    super();
  }

  override retrieveMainData(): Observable<ArtikelWithBookmark> {
    return this.watwatService.getArtikel(
      parseArtikelId(this.activatedRoute.snapshot.paramMap.get('artikelId')),
    );
  }

  getCustomPageTitle(): Observable<PageTitle> {
    return this.generateDefaultTitle((data) => data.title, 'watwat.artikel-page.title');
  }

  onClickedBookmark(
    hasBookmarked: boolean,
    artikelId: ArtikelId,
    bookmarkId: BookmarkId | undefined,
  ) {
    if (hasBookmarked) {
      this.bookmarkService
        .addBookmark({
          itemId: String(artikelId),
          itemType: 'ARTIKEL',
        })
        .subscribe(() => this.refreshMainData());
    } else {
      if (bookmarkId) {
        this.bookmarkService.deleteBookmark(bookmarkId).subscribe(() => this.refreshMainData());
      }
    }
  }

  onShareArtikel({ gesprekId, gesprekNaam, resourceId, profielId }: IShareArtikel) {
    this.berichtenService
      .stuurTekstBericht(
        gesprekId,
        {
          type: 'GEDEELDE_RESOURCE',
          resourceId,
          resourceType: 'ARTIKEL',
          deelnemerId: profielId,
        },
        'chat',
      )
      .subscribe(() => {
        this.toastService.showToast({ header: `Artikel gedeeld met ${gesprekNaam}` });
      });
  }
}
