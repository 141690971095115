import { Rol } from './persoon.dto';
import {
  AfwezigheidDto,
  Avatar,
  ProfielDto,
  ProfielFotoId,
  ProfielId,
  ProfielType,
} from './profiel.dto';
import { ApiProperty } from '@nestjs/swagger';

export class ActiefTeamlidDto extends ProfielDto {
  @ApiProperty({ type: String })
  readonly viewType!: 'TEAMLID';
  @ApiProperty({ type: String })
  readonly rol!: Rol;
}

export class GeblokkeerdTeamlidDto {
  @ApiProperty({ type: String })
  readonly viewType!: 'TEAMLID';
  @ApiProperty({ type: String })
  readonly id!: ProfielId;
  @ApiProperty({ type: String })
  readonly status = 'GEBLOKKEERD';
  @ApiProperty({ type: String })
  readonly rol!: Rol;
  @ApiProperty()
  readonly voornaam!: string;
  @ApiProperty()
  readonly naam!: string;
}

export class GeblokkeerdeWeergaveTeamlidDto {
  @ApiProperty({ type: String })
  readonly viewType!: 'TEAMLID';
  @ApiProperty({ type: String })
  readonly id!: ProfielId;
  @ApiProperty({ type: String })
  readonly status = 'GEBLOKKEERDE_WEERGAVE';
  @ApiProperty()
  readonly voornaam!: string;
  @ApiProperty()
  readonly naam!: string;
}

export class InactiefTeamlidDto {
  @ApiProperty({ type: String })
  readonly viewType!: 'TEAMLID';
  @ApiProperty({ type: String })
  readonly id!: ProfielId;
  @ApiProperty({ type: String })
  readonly status = 'INACTIEF';
  @ApiProperty()
  readonly voornaam!: string;
  @ApiProperty()
  readonly naam!: string;
}

export class UitgenodigdTeamlidDto {
  @ApiProperty({ type: String })
  readonly viewType!: 'TEAMLID';
  @ApiProperty({ type: String })
  readonly id!: ProfielId;
  @ApiProperty({ type: String })
  readonly status = 'UITGENODIGD';
  @ApiProperty()
  readonly type!: ProfielType;
  @ApiProperty()
  readonly voornaam!: string;
  @ApiProperty()
  readonly naam!: string;
  @ApiProperty()
  readonly avatar!: Avatar;
  @ApiProperty()
  readonly hasProfielFoto!: boolean;
  @ApiProperty({ type: String })
  readonly profielFotoId!: ProfielFotoId;
  @ApiProperty()
  readonly afwezigheid!: AfwezigheidDto;
  //TODO: Backwards compatibility: 1.003, remove unneeded profielType
  //readonly profielType!: ProfielType;
  @ApiProperty({required: false})
  readonly bio?: string;
  @ApiProperty({required: false})
  readonly adres?: string;
  @ApiProperty({type: [String]})
  readonly links!: string[];
}

export type TeamlidProfielDto =
  | ActiefTeamlidDto
  | GeblokkeerdTeamlidDto
  | GeblokkeerdeWeergaveTeamlidDto
  | InactiefTeamlidDto
  | UitgenodigdTeamlidDto;
