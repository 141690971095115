import { IdOf } from '../branded-type';
import { ApiProperty } from '@nestjs/swagger';
import { Thema } from './thema.dto';
import { FotoId } from './gebeurtenis.dto';
import { ProfielId } from './profiel.dto';
import {FileId} from "./bericht.dto";

export type SuggestieId = IdOf<'suggestie'>;
export type SuggestieStatus = 'ACTIEF' | 'AFGEWEZEN' | 'AANVAARD';

class FileData {
  @ApiProperty({ type: String })
  readonly id!: FileId;
  @ApiProperty()
  readonly naam!: string;
}

export class GebeurtenisDataDto {
  @ApiProperty({required: false})
  readonly titel?: string;
  @ApiProperty({required: false})
  readonly omschrijving?: string;
  @ApiProperty({required: false})
  readonly startDatum?: string;
  @ApiProperty({ type: String, required: false })
  readonly thema?: Thema;
  @ApiProperty({ type: String, required: false})
  readonly imageId?: FotoId;
  @ApiProperty({type: [FileData]})
  readonly bijlagen!: FileData[];
}

export class GebeurtenisSuggestieDto {
  @ApiProperty({ type: String })
  readonly id!: SuggestieId;
  @ApiProperty({ type: String })
  readonly suggestedById!: ProfielId;
  @ApiProperty({ type: String })
  readonly status!: SuggestieStatus;
    @ApiProperty()
  readonly titel!: string;
    @ApiProperty()
  readonly aangemaaktOpTijdstip!: string;
    @ApiProperty()
  readonly gebeurtenisData!: GebeurtenisDataDto;
}

export class GebeurtenisSuggestieUpsertDto {
    @ApiProperty()
  readonly titel!: string;
  @ApiProperty({ type: String })
  readonly suggestedToId!: ProfielId;
    @ApiProperty()
  readonly gebeurtenisData!: GebeurtenisDataDto;
}
