import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import {
  BehaviorSubject,
  distinctUntilChanged,
  exhaustMap,
  filter,
  finalize,
  from,
  Observable,
  of,
  Subject,
  switchMap,
  take,
} from 'rxjs';
import AuthService from './auth.service';
import { isNativeApp } from '../../utils';
import { LoggingService } from '../../core/logging.service';

export type LoginResult = {
  type: 'biometric-login' | 'acm-idm-login' | 'home' | 'canceled';
  modal?: HTMLIonModalElement;
};

@Injectable({
  providedIn: 'root',
})
export default class BackgroundDetectionService {
  private readonly _active$ = new BehaviorSubject<boolean>(true);

  get active$(): Observable<boolean> {
    return this._active$.asObservable();
  }

  private _localAppState = new Subject<boolean>();

  constructor(
    private readonly authService: AuthService,
    private readonly loggingService: LoggingService,
  ) {}

  initialize() {
    this._localAppState
      .pipe(distinctUntilChanged())
      .pipe(
        exhaustMap((active) =>
          from(App.getLaunchUrl()).pipe(
            switchMap((launchUrl) => {
              if (active && !launchUrl) {
                return this.authService
                  .retrieveUser('from-background')
                  .pipe(finalize(() => this._active$.next(true)));
              } else {
                this._active$.next(active);
                return of(undefined);
              }
            }),
          ),
        ),
      )
      .subscribe();

    if (isNativeApp()) {
      App.addListener('appStateChange', (appState) => {
        const active = appState.isActive;
        this.loggingService.log('AppStateChange to', active);
        this._localAppState.next(active);
      });
    }
  }

  isCurrentlyActive() {
    return this.active$.pipe(take(1));
  }

  waitUntilActive(): Observable<boolean> {
    return this.active$.pipe(
      filter((active) => active),
      take(1),
    );
  }
}
