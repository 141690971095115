import { BrandedType } from '../branded-type';
import { ApiProperty } from '@nestjs/swagger';

export type BewaardItemId = BrandedType<string, 'bewaard-item'>;
export function validateBewaardItemId(id?: string | null): BewaardItemId {
  if (!id) {
    throw new Error('id is undefined');
  }

  return id as BewaardItemId;
}

export class BewaardeLinkUpsertDto {
  readonly url!: string;
  readonly opmerking?: string;
}

export class BewaardeLinkDto {
  @ApiProperty({ type: String })
  readonly id!: BewaardItemId;
  @ApiProperty()
  readonly url!: string;
  @ApiProperty({required: false})
  readonly opmerking?: string;
}
