import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { BatchSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { ZoneContextManager } from '@opentelemetry/context-zone-peer-dep';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { SEMRESATTRS_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import { Resource } from '@opentelemetry/resources';
import { environment } from '../../environments/environment';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { filterUuidFromString, removeQueryParamsFromUrl } from '../utils';

const resource = new Resource({
  [SEMRESATTRS_SERVICE_NAME]: 'parkour-web-app-frontend',
});

if (environment.opentelemetry.enabled) {
  const provider = new WebTracerProvider({ resource });
  // provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
  provider.addSpanProcessor(
    new BatchSpanProcessor(
      new OTLPTraceExporter({
        url: environment.opentelemetry.tracingUrl,
      }),
    ),
  );
  provider.register({
    contextManager: new ZoneContextManager(),
  });

  registerInstrumentations({
    instrumentations: [
      new FetchInstrumentation({
        applyCustomAttributesOnSpan: (span, request, result) => {
          if (request instanceof Request) {
            span.updateName(
              request.method + ' ' + removeQueryParamsFromUrl(filterUuidFromString(request.url)),
            );
          } else if (result instanceof Response) {
            span.updateName(
              request.method + ' ' + removeQueryParamsFromUrl(filterUuidFromString(result.url)),
            );
          }
        },
      }),
      new XMLHttpRequestInstrumentation({
        applyCustomAttributesOnSpan: (span, xhr) => {
          span.updateName(removeQueryParamsFromUrl(filterUuidFromString(xhr.responseURL)));
        },
      }),
      new UserInteractionInstrumentation(),
    ],
  });
}
