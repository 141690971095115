export const environment = {
  production: true,
  API_BASE_URL: 'https://parkour-demo.opgroeien.be',
  watWatBaseUrl: 'https://www.watwat.be',
  globalHeaderEmbedCode:
    'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/widget/275a984a-0a80-456d-9204-97e1a22d446d',
  jitsiBaseUrl: '8x8.vc',
  jitsiApiEmbedCode:
    'https://8x8.vc/vpaas-magic-cookie-2dad2a8ba0994c23967e79f928d42ca7/external_api.js',
  ongelezenMeldingenInterval: 30000,
  opentelemetry: {
    enabled: false,
    tracingUrl: '',
  },
  logging: 'none',
  matomo: {
    enabled: false,
    trackerUrl: '',
    siteId: 1,
    dimensions: {
      profielType: 1,
      contextType: 2,
    },
  },
  mockGlobalHeader: true,
  debugOptionsEnabled: false,
  showDebugErrors: true,
};
