import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InfiniteScrollCustomEvent, IonInfiniteScroll } from '@ionic/angular/standalone';
import { ArtikelSummaryWithBookmark } from '../../../shared/services/watwat.service';
import { ArtikelId, BookmarkId } from 'parkour-web-app-dto';
import { CoreModule } from '../../../core/core.module';
import { ArtikelCardComponent } from '../../../watwat/components/artikel-card/artikel-card.component';
import {
  ParkourBlockPlaceholderComponent,
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
} from '@parkour/ui';
import { PaginatedData } from '../../../shared/parkour-pagination';

@Component({
  selector: 'parkour-bewaarde-artikels',
  standalone: true,
  imports: [
    CoreModule,
    ArtikelCardComponent,
    ParkourButtonComponent,
    ParkourBlockPlaceholderComponent,
    ParkourLoadingSpinnerComponent,
    IonInfiniteScroll,
  ],
  templateUrl: './bewaarde-artikels.component.html',
})
export class BewaardeArtikelsComponent {
  @Input({ required: true }) artikelsData!: PaginatedData<ArtikelSummaryWithBookmark>;
  @Input({ required: true }) active!: boolean;

  @Output()
  readonly artikelBookmarkClick = new EventEmitter<{
    bookmarkId: BookmarkId | undefined;
    artikelId: ArtikelId;
    hasBookmarked: boolean;
  }>();

  @Output()
  readonly fetchNextPage = new EventEmitter<InfiniteScrollCustomEvent>();

  constructor() {}

  onBewaardeArtikelsInfinite(event: InfiniteScrollCustomEvent) {
    this.fetchNextPage.emit(event);
  }

  onArtikelBookmarkClicked(param: {
    bookmarkId: BookmarkId | undefined;
    artikelId: ArtikelId;
    hasBookmarked: boolean;
  }) {
    this.artikelBookmarkClick.emit(param);
  }

  protected readonly String = String;
}
