import {ApiProperty} from '@nestjs/swagger';

export class LoginWithTokenDto {
  @ApiProperty()
  readonly token!: string;
  @ApiProperty()
  readonly deviceId!: string;
}

export class GenerateBiometricsTokenDto {
  @ApiProperty()
  readonly deviceId!: string;
}

export class DeleteBiometricsTokenDto {
  @ApiProperty()
  readonly deviceId!: string;
}

export type SessionType = 'native_acm_idm' | 'native_biometrics' | 'web';

export const isNativeSession = (sessionType: SessionType | undefined) => {
  return sessionType === 'native_acm_idm' || sessionType === 'native_biometrics'
}