import { MijnProfielDto } from './profiel.dto';
import { IdOf } from '../branded-type';
import { ApiProperty } from '@nestjs/swagger';

export type PersoonId = IdOf<'persoon'>;

export class AangemeldZonderProfielUserDto {
  @ApiProperty({ type: String })
  readonly role!: 'PERSOON';
  @ApiProperty({ type: String })
  readonly persoonId!: PersoonId;
  @ApiProperty()
  readonly voornaam!: string;
  @ApiProperty()
  readonly naam!: string;
}

export class AangemeldeUserDto {
  @ApiProperty({ type: String })
  readonly role!: 'AANGEMELD';
  @ApiProperty({ type: String })
  readonly persoonId!: PersoonId;
  @ApiProperty()
  readonly voornaam!: string;
  @ApiProperty()
  readonly naam!: string;
  @ApiProperty()
  readonly profiel!: MijnProfielDto;
}

export class AnonymousUserDto {
  @ApiProperty({ type: String })
  readonly role!: 'ANONYMOUS';
}

export type UserDto = AnonymousUserDto | AangemeldZonderProfielUserDto | AangemeldeUserDto;

export function validatedPersoonId(persoonId?: string | null): PersoonId {
  if (!persoonId) throw new Error('persoonId is undefined');
  return persoonId as PersoonId;
}
