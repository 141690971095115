import { Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { AangemeldeUser } from '../../../authentication/user';
import { ProfielService } from '../../service/profiel.service';
import { ProfielCreateService } from '../../service/profiel-create.service';
import { ContextService } from '../../../shared/services/context.service';
import { Context } from '../../../shared/model/context';
import {
  meldingenForKalenderPage,
  meldingenForMeldingenPage,
  meldingenForMijnProfielPage,
  meldingenForTeamPage,
} from '../../../meldingen/config';
import {
  ParkourActionComponent,
  ParkourBadgeComponent,
  ParkourButtonComponent,
  ParkourCardComponent,
  ParkourNewIndicatorComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { ProfielFotoComponent } from '../../../shared/components/profiel-foto/profiel-foto.component';
import { ProfielSelectComponent } from '../../../profiel/component/profiel-select/profiel-select.component';
import { ProfielFotoPipe } from '../../../shared/pipes/profiel-foto.pipe';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { RolLabelWithDefaultPipe } from '../../../shared/pipes/rol-label-with-default.pipe';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { ParkourProfielNavItemComponent } from '../../component/profiel-nav-item/profiel-nav-item.component';
import { ActiefVoorMezelfDirective } from '../../../shared/directives/actief-voor-mezelf.directive';
import { ActiefAlsJongereDirective } from '../../../shared/directives/actief-als-jongere.directive';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { Profiel } from '../../model/profiel';
import { isNativeApp } from '../../../utils';
import AuthService from '../../../authentication/service/auth.service';
import { ProfielCreateOption } from 'parkour-web-app-dto';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';

export type ProfielHomePageData = {
  profielCreateOptions: ProfielCreateOption[];
  user: AangemeldeUser;
  context: Context;
  mijnProfielen: Profiel[];
  profiel: Profiel;
};

@Component({
  standalone: true,
  templateUrl: './profiel-home.page.html',
  imports: [
    ParkourPageImageComponent,
    ProfielFotoComponent,
    ProfielSelectComponent,
    ParkourNewIndicatorComponent,
    ParkourCardComponent,
    ProfielFotoPipe,
    ParkourButtonComponent,
    ParkourActionComponent,
    ParkourBadgeComponent,
    AsyncPipe,
    TranslateModule,
    ProfielnaamPipe,
    RolLabelWithDefaultPipe,
    PageLayoutComponent,
    PageSectionDirective,
    ParkourProfielNavItemComponent,
    ActiefVoorMezelfDirective,
    NgIf,
    ActiefAlsJongereDirective,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    IonContent,
    IonHeader,
    PageContentDirective,
  ],
})
export class ProfielHomePage extends ParkourDataPage<ProfielHomePageData> {
  wisselTeamIndicator$ = this.meldingenService.hasWisselTeamMeldingen();
  hasKalenderMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForKalenderPage.allMeldingen,
  );
  hasMeldingenPageMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForMeldingenPage.allMeldingen,
  );
  hasMijnProfielMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForMijnProfielPage.allMeldingen,
  );
  hasMijnTeamMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForTeamPage.allMeldingen,
  );

  constructor(
    private readonly contextService: ContextService,
    private readonly authService: AuthService,
    private readonly profielService: ProfielService,
    private readonly profielCreateService: ProfielCreateService,
  ) {
    super();
  }

  override retrieveMainData(): Observable<ProfielHomePageData> {
    return combineLatest({
      user: this.authService.getAangemeldeUser$(),
      context: this.contextService.context$,
      mijnProfielen: this.profielService.getMyProfielen(),
      profiel: this.profielService.getCurrentUserProfiel$(),
      profielCreateOptions: this.profielService.getProfielCreateOptions(),
    });
  }

  onProfielSelect(profiel: Profiel) {
    this.authService.switchProfiel(profiel.id, 'app/me/profiel').subscribe();
  }

  async onCreatePersoonlijkProfiel() {
    await this.profielCreateService.startCreatingProfiel(
      '/app/me/profiel',
      this.authService.createMyProfiel,
      this.authService.hasJongereHoedanigheid,
      'persoonlijk',
    );
  }

  async onCreateProfessioneelProfiel() {
    await this.profielCreateService.startCreatingProfiel(
      '/app/me/profiel',
      this.authService.createMyProfiel,
      this.authService.hasJongereHoedanigheid,
      'professioneel',
    );
  }

  protected readonly isNativeApp = isNativeApp;
}
