import { generalBerichtAnalyticsEvents } from '../bericht/berichten.analytics-events';

export const doelenEvents = [
  'behaald',
  'aangemaakt',
  'verwijderd',
  'gedeeldMetNiemand',
  'gedeeldMetSpecifiek',
  'gedeeldMetIedereen',
  'gewijzigd',
  'voorstelAangemaakt',
  'voorstelAanvaard',
  'voorstelGeweigerd',
  'doelReactieToegevoegd',
  'doelReactieVerwijderd',
  ...generalBerichtAnalyticsEvents,
] as const;
