@if (isStaticCard) {
  <div class="flex items-center gap-x-4">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
} @else {
  <parkour-action
    [link]="['gesprek', gesprek.id]"
    classes="flex items-center gap-x-4"
    [ariaLabel]="gesprek | ariaLabelGesprek: (gesprek | laatsteBerichtInhoud | async) : user"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </parkour-action>
}

<ng-template #content>
  <div class="relative">
    <parkour-new-indicator
      *ngIf="ongelezenBerichtenMeldingen"
      [show]="
        ongelezenBerichtenMeldingen &&
        shouldShowNewIndicator(gesprek.id, ongelezenBerichtenMeldingen)
      "
    />

    <parkour-gesprek-foto-badge
      [gesprek]="gesprek"
      [overrideImage]="false"
      [size]="'4'"
      [user]="user"
    />
  </div>

  <div class="grid grid-cols-1 flex-1">
    <div class="flex justify-between items-center">
      <p class="typo-body variant-body-fat truncate pr-8">
        {{ gesprek.getGesprekNaam(user) }}
      </p>

      @if (!isStaticCard) {
        <span class="text-n-600 typo-body variant-body-small">{{
          getLaatsteBerichtTijdstip(gesprek)
        }}</span>
      }
    </div>

    <p class="text-n-600 typo-body truncate">
      <ng-container *ngIf="gesprek.status === 'GEARCHIVEERD'; else nietGearchiveerd">
        <span class="italic">{{ gesprek.getGearchiveerdMessage() }}</span>
      </ng-container>
      <ng-template #nietGearchiveerd>
        {{ gesprek | laatsteBerichtInhoud | async }}
      </ng-template>
    </p>
  </div>

  <ng-content></ng-content>
</ng-template>
