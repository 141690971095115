import { inject, Injectable } from '@angular/core';
import { MatomoRouterInterceptor, MatomoTracker } from 'ngx-matomo-client';
import { NavigationEnd } from '@angular/router';
import { filterUuidFromString, removeQueryParamsFromUrl } from '../utils';

@Injectable()
export class UrlFilterInterceptor implements MatomoRouterInterceptor {
  private readonly tracker = inject(MatomoTracker);

  beforePageTrack(event: NavigationEnd): void {
    this.tracker.setCustomUrl(removeQueryParamsFromUrl(filterUuidFromString(event.url)));
  }
}
