import { ApiProperty } from '@nestjs/swagger';
import { BerichtId, CreateReactieBerichtDto } from './bericht.dto';
import { ProfielId } from './profiel.dto';

export const interactieEmojis = ['THUMBS_UP', 'OK', 'HEART', 'HUNDRED', 'SAD_FACE'] as const;

export type InteractieEmoji = (typeof interactieEmojis)[number];

export interface ReactieEvent {
  berichtId: BerichtId;
  reactie: CreateReactieBerichtDto;
}

export type ReactieId = string;

export class UpdateReactieDto {
  @ApiProperty({ type: String })
  readonly value!: InteractieEmoji;
}

export class ReactieIdDto {
  @ApiProperty()
  readonly value!: string;
}

export class ReactieDto {
  @ApiProperty({ type: String, required: false })
  readonly id?: ReactieId;
  @ApiProperty({ type: String })
  readonly type!: InteractieEmoji;
  @ApiProperty({ type: String })
  readonly reactie!: InteractieEmoji; //TODO: Backwards compatibility: 1.001.0041
  @ApiProperty({ type: String })
  readonly ownerId!: ProfielId;
  @ApiProperty({ type: String })
  readonly deelnemerId!: ProfielId;
}

export type ReactiesDto = ReactieDto[];

export class ReactieOverviewDto {
  @ApiProperty({type: [ReactieDto]})
  readonly reacties!: ReactieDto[];
  @ApiProperty({required: false})
  readonly myReaction?: ReactieDto;
}
