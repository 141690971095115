import { ApiProperty } from '@nestjs/swagger';

export class JitsiGesprekDto {
  @ApiProperty({ type: String })
  readonly room!: string;
  @ApiProperty({ type: String })
  readonly jwt!: string;
}

export type BeeldbelGesprekStatus = 'NIET_BEGONNEN' | 'GESTART' | 'GESTOPT';

export class Deelnemer {
  @ApiProperty({ type: String })
  id!: string;
  @ApiProperty()
  joined!: boolean;
}

export class BeeldbelGesprekDto {
  readonly id!: string;
  @ApiProperty({ type: String })
  readonly status!: BeeldbelGesprekStatus;
  @ApiProperty()
  readonly eigenaar!: Deelnemer;
  @ApiProperty()
  readonly deelnemer!: Deelnemer;
}
